* {
  font-family: monospace;
}

#start {
  font-size: 100px;
  text-align: center;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 20px;
}

#code,
#submit-code {
  font-size: x-large;
}

#timer {
  font-size: 100pt;
  text-align: center;
}

.display-none {
  display: none !important;
}

#bomb-status-text {
  font-size: large;
}
